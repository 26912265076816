import React, {useState,useEffect} from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import '../css/Footer.css';
import axios from 'axios';
import { API_BASE_URL , API_IMAGE_URL} from '../config'; 

const Footer = () => {

  const [projectData, setProjectData] = useState({});
  const [socaiMedia,setSocialMediaData] = useState([]);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/links`);
        console.log("hiiiiiiiiiiii")
        console.log(response.data.data.data)
        // Assuming the API response has relevant meta information
        setProjectData({
          phone: response.data.data.data[0].phone || "",
          phone_2: response.data.data.data[0].phone_second || "",
           email: response.data.data.data[0].email || "",
           email_2: response.data.data.data[0].email_second || "",
           address :response.data.data.data[0].address || "",
           city :response.data.data.data[0].city || "",
        //   imageUrl: response.data.image || "DefaultImageUrl.jpg",
        });
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    const fetchProjectSocialMedia = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/social`);
        console.log(response.data.data.length)
        for (let index = 0; index < response.data.data.length; index++) {
          console.log(response.data[index])
          setSocialMediaData((pre)=>[...pre,response.data.data[index]])
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };


    fetchProjectData();
     fetchProjectSocialMedia();
  }, []);


  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [message , setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_BASE_URL}/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {

      setFormData({ ...formData, name: '',email: '',message:'' });
      setMessage('Message sent successfully!');
      } else {

        setMessage('Failed to send message.');

      }
    } catch (error) {
      setMessage('An error occurred while sending the message.');
    }
  };


  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">

          {/* Our Services Section */}
          <div className="our-services card">
            <h1 style={{fontSize:"46px"}}>Our Services</h1>
            <ol>
              <li><h2 className='service-list'>Design</h2></li>
              <li><h2 className='service-list'>WoodWork</h2></li>
              <li><h2 className='service-list'>Construction</h2></li>
              <li><h2 className='service-list'>Technologies</h2> </li>
            </ol>
          </div>

          {/* Contact Us Form */}
          <div className="contact-form card">
            <h1  style={{fontSize:"46px"}}>Contact Us</h1>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input type="text" placeholder="Your Name" name="name" value={formData.name} onChange={handleChange}required />
              </div>
              <div className="form-group">
                <input type="email" placeholder="Your Email" name="email" value={formData.email} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <textarea placeholder="Your Message" name="message" value={formData.message} onChange={handleChange} required></textarea>
              </div>
              <button type="submit">Send</button>
            </form>
            {message}

          </div>

          <div className="contact-info card">
            <h1 className="footer-title">Contact Information</h1>
            <p className='contact-list'><FaEnvelope /> {projectData.email} {projectData.email_2}</p>
            <p className='contact-list'><FaPhone /> {projectData.phone} {projectData.phone_2}</p>
            <p className='contact-list'><FaMapMarkerAlt /> {projectData.address} {projectData.city}</p>
          </div>
        </div>

        {/* Social Media Links */}
        <div className="social-media">
          <div className="icons">
          {socaiMedia.map((project) => (
            <a href={project.social_url} target="_blank" rel="noopener noreferrer">
            <img src={`${API_IMAGE_URL}/${project.social_icon}`} alt={project.social_name} style={{height:"30px",width:"30px", borderRadius:"50px"}}/>
          </a>
          ))}
            
  
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="footer-bottom">
          <p>© 2024 Majestic Trading PLC. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
