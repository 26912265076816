import React, { useState ,useEffect} from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { color } from 'framer-motion';
import axios from 'axios';
import { API_BASE_URL, API_IMAGE_URL } from '../config';

// List of Ethiopian places with their coordinates
const places = [
    { name: 'Addis Ababa', location: [9.030118, 38.761492] },
    { name: 'Lalibela', location: [12.034147, 39.047816] },
    { name: 'Gondar', location: [12.603017, 37.452131] },
    { name: 'Axum', location: [14.131559, 38.715915] },
    { name: 'Bahir Dar', location: [11.574207, 37.361434] },
    { name: 'Harar', location: [9.312051, 42.122273] },
    { name: 'Dire Dawa', location: [9.593060, 41.866637] },
];

// A component to change the map's center and zoom
const ChangeView = ({ center, zoom }) => {
    const map = useMap(); // Access the map instance
    map.setView(center, zoom); // Change map's view
    return null;
};

const initialCenter = [9.030118, 38.761492]; // Initial map center on Addis Ababa

const MapAndPlaces = () => {
    const [places,setPlaces] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPlaces = async () => {
          try {
            const response = await axios.get(`${API_BASE_URL}/location`); 
            console.log(response.data.data.data.length)
            for (let index = 0; index < response.data.data.data.length; index++) {
    
              console.log("it is working" , response.data.data.data[index])
            //   for (let index1 = 0; index1 < response.data.data.data[index]['sub_categories'].length; index1++) {
                // console.log(index1)
                setPlaces((prevPortfolioList) => [...prevPortfolioList, response.data.data.data[index]]); 
              // setCategory((prevPortfolioList) => [...prevPortfolioList, ...index1]);
            //   }
            }
           
            
            setLoading(false);
          } catch (err) {
            setError('Failed to load data');
            setLoading(false);
          }
        };
        
        fetchPlaces();
      
      }, []);
    const [selectedPlace, setSelectedPlace] = useState(null); // Track selected place
    const [zoom, setZoom] = useState(6); // Initial zoom level (country view)

    const handlePlaceClick = (place) => {
        setSelectedPlace([place.latitude,place.longitude]); // Set selected place location
        setZoom(12); // Zoom in to street-level when place is clicked
    };

    return (
        <div style={styles.container}>
            {/* Fullscreen Map */}
            <div style={styles.mapSection}>
                <MapContainer center={initialCenter} zoom={zoom} style={styles.map}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />

{/* <TileLayer
  url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
/> */}

                    {/* Conditionally render ChangeView to zoom and move to the selected place */}
                    {selectedPlace && <ChangeView center={selectedPlace} zoom={zoom} />}
                    
                    {/* Display markers for all Ethiopian places */}
                    {places.map((place, index) => (
                        <Marker key={index} position={[place.latitude,place.longitude]}>
                            <Popup>{place.location_name}
                                <p>{place.description}</p>

                              {/* <p> The href attribute requires a valid value to be accessible. Provide a valid, navigable address as the href value. If you cannot provide a valid href, but still need the element to resemble a link, use a button and change it with appropriate styles.</p>  */}
                            </Popup>
                            
                        </Marker>
                    ))}
                </MapContainer>
            </div>

            {/* Fixed Places List Overlay */}
            <div style={styles.listSection}>
                <h1 style={styles.title}>Places</h1>
                <ul style={styles.placesList}>
                    {places.map((place, index) => (
                        <li
                            key={index}
                            style={styles.placeItem}
                            onClick={() => handlePlaceClick(place)} 
                        >
                           <h1> {place.location_name}</h1>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

const styles = {
    container: {
        position: 'relative',
        width: '100vw', // Full viewport width
        height: '100vh', // Full viewport height
        overflow: 'hidden',
        filter: "grayscale(100%)",
        
    },
    mapSection: {
        width: '100vw', // Map takes up full viewport width
        height: '100vh', // Map takes up full viewport height
    },
    map: {
        width: '100%',
        height: '100%',
    },
    listSection: {
        position: 'absolute', // Fixed position for the list
        top: '0',
        right: '0',
        width: '400px',
        height: '100vh',
        backgroundColor: 'rgba(0.2, 0.2, 0, 0.6)', // Semi-transparent background
        padding: '20px',
        overflowY: 'auto',
        zIndex: 1000, // Ensure it is above the map
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px',
        fontSize: '3.5rem',
        color:"white"
    },
    placesList: {
        listStyle: 'none',
        padding: '0',
        

    },
    placeItem: {
        padding: '30px 30px',
        borderBottom: '1px solid #00f000',
        cursor: 'pointer',
        fontSize: '1.4rem',
        transition: 'background-color 0.2s ease',
        color:'white',
        textAlign: 'left',
        
    },
    placeItemHover: {
        backgroundColor: 'black',
    },
};

export default MapAndPlaces;
