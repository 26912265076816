import React from 'react';
import home_photo from '../images/logo.png'
import Header from './Header';
import Footer from './Footer';
import { FaUsers, FaCalendarAlt, FaProjectDiagram } from 'react-icons/fa';

const About = () => {
  return (
    <div>
      <Header />
 


<section id="about-container" className="py-16 bg-white">
      <div className="container mx-auto px-6">

      <div className="text-center mb-10 ">
          <h1 className="text-5xl font-bold mb-4 text-gray-900">About Us</h1>
          <h1 className="text-lg max-w-2xl mx-auto bg-gray-100 p-8 rounded-lg shadow-lg">
          Welcome to Majestic, your trusted partner in shaping spaces, identities, and digital experiences.
           Nestled in the heart of Addis Ababa, Ethiopia, we bring three years of passionate dedication to interior design, 
           finishing, construction, branding, website design, and system design.

          </h1>
        </div>

        {/* Introduction Section */}
        <div className="flex flex-col lg:flex-row items-center mb-12">
         
         
      
{/*         
          <div className="lg:w-1/2 w-full p-4 bg-gray-100 p-8 rounded-lg shadow-lg">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">About Us</h2>
            <p className="text-lg text-gray-600 mb-4">
            Welcome to Majestic, your trusted partner in shaping spaces, identities, and digital experiences. Nestled in the heart of Addis Ababa, Ethiopia, we bring three years of passionate dedication to interior design, finishing, construction, branding, website design, and system design.

Our Journey

In just three years, Majestic has
emerged as a creative powerhouse, dedicated to redefining environments and
brand narratives. Our journey has been marked by a commitment to excellence,
continuous learning, and a relentless pursuit of innovative solutions.
            </p>
            <a href="#contact" className="bg-gray-500 text-white py-2 px-6 rounded-lg hover:bg-gray-700 transition duration-300">
              Get in Touch
            </a>
          </div>

          <div className="lg:w-1/2 w-full p-4 bg-gray-100 p-8 rounded-lg shadow-lg">
          <h2 className="text-4xl font-bold text-gray-800 mb-6">Our Journey</h2>
          <p className="text-lg text-gray-600 mb-4">

In just three years, Majestic has
emerged as a creative powerhouse, dedicated to redefining environments and
brand narratives. Our journey has been marked by a commitment to excellence,
continuous learning, and a relentless pursuit of innovative solutions.</p>
            </div> */}
         
        </div>

        {/* Mission and Vision Section */}
        <div className="grid lg:grid-cols-2 gap-12">
          {/* Mission */}
          <div className="bg-gray-100 p-8 rounded-lg shadow-lg">
            <h3 className="text-3xl font-semibold text-regal-blue mb-4">Our Mission</h3>
            <p className="text-lg text-gray-600">
             To leverage modern technologies to solve critical problems and empower businesses to thrive.
            </p>
          </div>
      
          {/* Vision */}
          <div className="bg-gray-100 p-8 rounded-lg shadow-lg">
            <h3 className="text-3xl font-semibold text-regal-blue mb-4">Our Vision</h3>
            <p className="text-lg text-gray-600">
            We envision a world where every organization has access to the technological tools necessary to succeed.
            </p>
          </div>
        </div>


                {/* Stats Section */}
                <div className="flex justify-around items-center mt-16">
          <div className="text-center">
            <FaUsers className="text-6xl text-gray-400 mb-4 ml-10"  />
            <h3 className="text-4xl font-bold">200</h3>
            <p className="text-gray-400">Happy Customers</p>
          </div>
          <div className="text-center">
            <FaCalendarAlt className="text-6xl text-gray-400 mb-4 ml-10" />
            <h3 className="text-4xl font-bold">3+</h3>
            <p className="text-gray-400">Established Year</p>
          </div>
          <div className="text-center">
            <FaProjectDiagram className="text-6xl text-gray-400 mb-4 ml-10" />
            <h3 className="text-4xl font-bold">300</h3>
            <p className="text-gray-400">Completed Projects</p>
          </div>
        </div>
      </div>
    </section>
    <div id='contact'>
    <Footer />
    </div>
  
    </div>

  );
};

export default About;
