import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Main Swiper CSS
import { Navigation, Pagination, Autoplay, EffectFade ,EffectCube,EffectCards} from 'swiper/modules'; // Import required modules
import ReactCompareImage from 'react-compare-image';
import furn_pic1 from '../images/1.jpeg'
import furn_pic2 from '../images/2.jpeg'
import furn_pic3 from '../images/3.jpeg'
import furn_pic4 from '../images/4.jpeg'
import Navbar from './Nabar';
import InteriorDesignBeforeAfter from './InteriorDesignBeforeAfter'
import BEFORE_IMAGE1 from '../images/before1.jpeg';
import AFTER_IMAGE1 from '../images/after1.jpeg';
import BEFORE_IMAGE2 from '../images/before2.jpeg';
import AFTER_IMAGE2 from '../images/after2.jpeg';
import BEFORE_IMAGE3 from '../images/before3.jpeg';
import AFTER_IMAGE3 from '../images/after3.jpeg';



const comparisons = [
  { before: BEFORE_IMAGE1, after: AFTER_IMAGE1 },
  { before: BEFORE_IMAGE3, after: AFTER_IMAGE3 },
  { before: BEFORE_IMAGE1, after: AFTER_IMAGE1 }
];

const imageList = [
  furn_pic1,
  furn_pic2,
  furn_pic3,
  furn_pic4,
];

const Contact = () => {
  const [bgImage, setBgImage] = useState(imageList[0]); // Initialize with the first image
  const swiperRef = useRef(null);

  const handleSlideChange = () => {
    const swiper = swiperRef.current.swiper;
    const activeIndex = swiper.activeIndex;
    setBgImage(imageList[activeIndex]); // Update the background image based on the active slide
  };

  return (
    <div>
      <Navbar />
      {/* Section with Dynamic Background Image */}
      <div
        className="dynamic-background"
       
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          transition: 'background-image 0.5s ease-in-out',
          height: '100vh',
          position: 'relative',
        }}
      >



  <div className="swiper-container">
        <Swiper
        ref={swiperRef}
        modules={[Navigation, Pagination, Autoplay, EffectCards]} 
        onSlideChange={handleSlideChange} 
        navigation
        pagination={{ clickable: true }} 
        loop={false} 
        autoplay={{
            delay: 3000, 
            disableOnInteraction: false, 
        }}
        effect="cards" 
        className='slider-new'
    >
        {imageList.map((image, index) => (
            <SwiperSlide key={index}>
                <img 
                    src={image} 
                    alt={`Card ${index + 1}`} 
                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '15px' }} 
                />  
            </SwiperSlide>
        ))}
    </Swiper>
    </div>

      </div>
     <section className="p-8 bg-gray-100">

    <div id="interior" className="">
    <h2 className="text-3xl font-bold text-center mb-8">Before and After Interiors</h2>
      <div className="grid grid-cols-3 gap-4">
        {comparisons.map((item, index) => (
          <div key={index} className="flex justify-center">
            <div className="w-full md:w-3/3">
              <ReactCompareImage 
                leftImage={item.before} 
                rightImage={item.after} 
              />
            </div>
          </div>
        ))}
      </div>
    </div>
    </section>
    </div>
  );
};

export default Contact;
