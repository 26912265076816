import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import { API_BASE_URL, API_IMAGE_URL } from '../config';
import { format } from 'date-fns';

const ProjectDetail = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/projects/${id}`);
        setProject(response.data.data);
        setSelectedImage(response.data.data.images[0].image_url); // Set the first image as default
        setLoading(false);
      } catch (err) {
        setError('Failed to load project data');
        setLoading(false);
      }
    };
    fetchProject();
  }, [id]);

  if (loading) return <p>Loading project...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="">
      {/* Header */}
      <Header />

      {/* Project Detail Content */}
      <div className="project-detail-container mx-auto">
        <button onClick={() => navigate(-1)} className="underline mb-2 hover:opacity-100" style={{color:"black",marginRight:"50%",backgroundColor:"white"}}>Back to Portfolio</button>

        <div className="flex flex-col lg:flex-row items-start gap-8">
          {/* Main Image with Fixed Size */}
          {/* <div className="main-image-container w-full lg:w-3/4 h-[500px]">
            <img
              src={`${API_IMAGE_URL}/${selectedImage}`}
              alt={project.project_title}
              className=" object-cover rounded-lg"
            />
          </div> */}

          <div className="main-image-container mb-4   lg:w-3/4  mx-auto ">
          <img
            src={`${API_IMAGE_URL}/${selectedImage}`}
            alt={project.project_title}
            className="w-full h-80 object-cover rounded-lg"
          />
        </div>

          {/* Thumbnail Gallery */}
          <div className="thumbnail-gallery flex lg:flex-col gap-4 lg:gap-2">
            {project.images.map((img, index) => (
              <img
                key={index}
                src={`${API_IMAGE_URL}/${img.image_url}`}
                alt={`Thumbnail ${index + 1}`}
                onClick={() => setSelectedImage(img.image_url)}
                className={`w-20 h-20 object-cover rounded-lg cursor-pointer border-2 ${
                  selectedImage === img.image_url ? 'border-yellow-500' : 'border-transparent'
                }`}
              />
            ))}
          </div>
        </div>

        {/* Project Title and Description */}
        <div className="mt-8 " style={{width:"68%"}}>
          <h2 className="text-3xl font-bold mb-2">{project.project_title}</h2>
          <h4 className="text-lg mb-4">Company: {project.client_name}</h4>

          <div className="mt-4">
            <h4 className="font-semibold text-lg mb-2">Description</h4>
            <p>{project.description}</p>
          </div>

          <div className="mt-4">
            <h4 className="font-semibold text-lg mb-2">Project Time</h4>
            <p>{format(new Date(project.start_date), 'yyyy-MM-dd')} - {format(new Date(project.end_date), 'yyyy-MM-dd')}</p>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ProjectDetail;
