import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { API_BASE_URL, API_IMAGE_URL } from '../config';

const ProjectMetaTags = () => {
  const [projectData, setProjectData] = useState(null);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/keyword`);
        console.log(response.data.data.data[0].keyword)
        // Assuming the API response has relevant meta information
        setProjectData({
           title: response.data.data.title || "Majestic Trading",
           description: response.data.data.data[0].keyword || "Default Description",
        //   imageUrl: response.data.image || "DefaultImageUrl.jpg",
        });
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    fetchProjectData();
  }, []);

  if (!projectData) return null; // Render null or loading component until data is fetched

  return (
    <HelmetProvider>
      <Helmet>
        <title>{projectData.title}</title>
        <meta name="description" content={projectData.description} />
        <meta property="og:title" content={projectData.title} />
        <meta property="og:description" content={projectData.description} />
        {/* <meta property="og:image" content={projectData.imageUrl} /> */}
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

    </HelmetProvider>
  );
};

export default ProjectMetaTags;
