import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Logo from '../images/logo.png';
import '../css/Navbar.css'; 
import LogoInteractive from './LogoInteractive'
import { FaCaretDown } from 'react-icons/fa';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);  // For mobile view
  const [activeMenu, setActiveMenu] = useState(null);
  const [visibleItems, setVisibleItems] = useState([]); // To track visible submenu items
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleMouseEnter = (menu) => {
    setActiveMenu(menu);
    // Show submenu items step by step
    const items = ['Finishing', 'Interiors Design', 'WoodWork','Software Development'];
    let delay = 0;

    items.forEach((item, index) => {
      setTimeout(() => {
        setVisibleItems((prev) => [...prev, item]);
      }, delay);
      delay += 200; // Increase delay for each item
    });
  };

  const handleMouseLeave = () => {
    setActiveMenu(null);
    setVisibleItems([]); // Hide all submenu items
  };

  const menuItems = [
    { name: 'Home' },
    { name: 'Services' },
    { name: 'About Us' },
    { name: 'Contact' },
  ];


  return (
    <header className="header bg-black">
      <nav className={` nav ${isOpen ? 'open' : ''}`}>
        <ul className="nav-links left">
        <li  className="menu-item"
          onMouseEnter={() => setHoveredIndex(0)}
          onMouseLeave={() => setHoveredIndex(null)}
          style={{
            color: hoveredIndex === 0 || hoveredIndex === null ? 'white' : 'black', // Color for hovered item and others
          }}>
               <a className =" " href="/">{menuItems[0].name}</a>
              </li>
            

        <li
          className="navbar-item "
          onMouseEnter={() => {handleMouseEnter('services') ; setHoveredIndex(1); }}
          onMouseLeave={()=>{handleMouseLeave();setHoveredIndex(null);}}
          style={{
            color: hoveredIndex === 1 || hoveredIndex === null ? 'white' : 'black', 
          }}>
        
          <a href="#" className='menu-item'><span>Services <FaCaretDown  className="dropdown-icon"/> </span></a>
          {activeMenu === 'services' && (
            <div className='sub_menu'>
              <div className="line-bn"></div>
            <ul className="submenu">
            <li><a href="/interior"><h2>Design</h2></a></li>
              <li><a href="/woodwork"><h2>WoodWork</h2></a></li>
              <li><a href="/construction"><h2>Construction</h2></a></li>
              <li><a href="/technology"><h2>Technologies</h2></a></li>
            </ul>
            </div>
          )}
        </li>
      
<li  className="menu-item" 
  onMouseEnter={() => setHoveredIndex(2)}
  onMouseLeave={() => setHoveredIndex(null)}
  style={{
    color: hoveredIndex === 2 || hoveredIndex === null ? 'white' : 'black',
  }}>

<a href="/portfolio" >Portfolio</a>
</li>


        </ul>
        <div className="logo-container">
        <div className="line"></div>
        <LogoInteractive />
        <div className="line"></div>
        </div>
        <ul className="nav-links right">
        <li  className="menu-item"

onMouseEnter={() => setHoveredIndex(3)}
onMouseLeave={() => setHoveredIndex(null)}
style={{
  color: hoveredIndex === 3 || hoveredIndex === null ? 'white' : 'black', // Color for hovered item and others
}}>

<a href="/news" >News</a>
</li>
     
<li  className="menu-item"
 onMouseEnter={() => setHoveredIndex(4)}
 onMouseLeave={() => setHoveredIndex(null)}
 style={{
   color: hoveredIndex === 4 || hoveredIndex === null ? 'white' : 'black', // Color for hovered item and others
 }}>
<a href="/aboutUs">About Us</a></li>

        </ul>
      </nav>
      
 
      {/* Hamburger Icon to toggle drawer */}
      <div className='toggle'>
      <button className="menu-toggle" onClick={toggleDrawer}>
        {isDrawerOpen ? '✖' : '☰'}
      </button>
      </div>

      {/* Drawer Menu (will show on small screens) */}
      <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/interior">Design</a></li>
          <li><a href="/woodwork">WoodWork</a></li>
          <li><a href="/construction">Construction</a></li>
          <li><a href="/technology">Technologies</a></li>
          <li><a href="/portfolio">Portfolio</a></li>
          <li><a href="/news">News</a></li>
          <li><a href="/aboutUs">About Us</a></li>
          
       
        </ul>
      </div>
    </header>
  );
};



export default Header;



