import React, { useState, useEffect} from 'react';
import '../css/ServiceList.css';
import Footer from './Footer';
import Header from './Header';
import { format } from 'date-fns';
import axios from 'axios';
import { API_BASE_URL , API_IMAGE_URL} from '../config'; 



const NewsPage = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [blogsList, setBlogsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const openModal = (project) => {
    setSelectedProject(project);
  };

  const closeModal = () => {
    setSelectedProject(null);
  };
    // Fetch the data from the API
useEffect(() => {
  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/blogs`); 
 
      setBlogsList(response.data.data.data); 
      setLoading(false);
    } catch (err) {
      setError('Failed to load data');
      setLoading(false);
    }
  };

  fetchBlogs();
}, []);



  return (
    <div>
         <Header />
  
    <div className="news-page-container">
      <h1 className="text-4xl font-bold text-center mb-8">Latest News</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {blogsList.map((news) => (
          <div key={news.id} className="news-card bg-white shadow-lg rounded-lg p-6">
            <img
                     src={`${API_IMAGE_URL}/${news.cover_image}`}
              alt={news.title}
              className="w-full h-48 object-cover rounded-t-lg mb-4"
            />
            <h2 className="text-2xl font-bold mb-2">{news.title}</h2>
            <p className="  mb-2">{news.snippet}</p>
            <p style={{ margin: 0, fontWeight: 'bold' }}>Witten by : {news.written_by}</p>
            <p className="text-sm text-gray-600 mb-2">{format(news.created_at, 'yyyy-MM-dd')}</p>
            {/* <p className="text-base mb-4">{news.excerpt}</p> */}
            <button className="px-6 py-2 bg-gray-500 text-white font-bold rounded-full"  onClick={() => openModal(news)}>
              Read More
            </button>
          </div>
        ))}
      </div>
    </div>

          {/* Modal */}
          {selectedProject && (

<div className="modal-overlay" onClick={closeModal}>

<div className="modal-content" onClick={(e) => e.stopPropagation()}>
  <button className="modal-close" onClick={closeModal}>
    &times;
  </button>

  <div className="modal-body">
  <img
              src={`${API_IMAGE_URL}/${selectedProject.cover_image}`}
              alt={selectedProject.title}
              className="w-full h-60 object-cover rounded-lg"
            />
  <h1>{selectedProject.title}</h1>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        {/* <img
            src={`${API_IMAGE_URL}/${selectedProject.author_image}`}
          alt={selectedProject.author_name}
          style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }}
        /> */}
        <div>
          <p style={{ margin: 0, fontWeight: 'bold' }}>{selectedProject.written_by}</p>
          <p style={{ margin: 0, color: 'gray' }}>{new Date(selectedProject.date_published).toLocaleDateString()}</p>
        </div>
      </div>
  
      <div dangerouslySetInnerHTML={{ __html: selectedProject.content }} />
    

            <button
              className=" mt-6 bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
  {/* <div dangerouslySetInnerHTML={{ __html: selectedProject.content }} /> */}
  </div>
</div>


      //   <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-30 " >
      //     <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6 absolute">
      //       <button
      //         className="absolute top-0 right-0 mt-4 mr-4 text-black text-2xl font-bold"
      //         onClick={closeModal}
      //       >
      //         &times;
      //       </button>
      //       <img
      //         src={`${API_IMAGE_URL}/${selectedProject.cover_image}`}
      //         alt={selectedProject.title}
      //         className="w-full h-60 object-cover rounded-lg"
      //       />

      // <h1>{selectedProject.title}</h1>
      // <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
      //   <img
      //       src={`${API_IMAGE_URL}/${selectedProject.author_image}`}
      //     alt={selectedProject.author_name}
      //     style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }}
      //   />
      //   <div>
      //     <p style={{ margin: 0, fontWeight: 'bold' }}>{selectedProject.author_name}</p>
      //     <p style={{ margin: 0, color: 'gray' }}>{new Date(selectedProject.date_published).toLocaleDateString()}</p>
      //   </div>
      // </div>
  
      // <div dangerouslySetInnerHTML={{ __html: selectedProject.snippet }} />
    

      //       <button
      //         className="mt-6 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
      //         onClick={closeModal}
      //       >
      //         Close
      //       </button>
      //     </div>
      //   </div>
      )}

    <Footer />
    </div>
  );
};

export default NewsPage;
